<template>
  <div class="sign-in-wrapper">

    <div class="sales-cta">
      <a href="https://orbitalstack.com/" target="blank">
        <img class="sign-in-logo" src="~@/assets/svg/sales-card.svg" alt="Schedule a Demo">
      </a>
      
      <div class="cta">
      <p class="big">Rapid insights to guide your design decisions now, saving you from costly mistakes later.</p>
      <div class="button-row"><a class="button" href="mailto:support@orbitalstack.com" target="_blank">Schedule a Demo</a></div>
      </div>

    </div>

    <div class="form-wrapper">

      <div class="sign-in-form" v-if="this.$route.name === 'ResetPassword'">
        <h4>Send reset password email</h4>

        <notification-banner v-if="emailError"
        theme='error'
        title='Invalid Email Address'
        message='Please re-enter your email address or try again'
        :show-close-button="false" />
        <notification-banner v-if="emailError === false"
        theme='success'
        title='Success'
        message='Please check your email for further instructions'
        :show-close-button="false" />
        <form class="username" @submit.prevent="sendPasswordResetEmail(email)">

          <div class="">
            <label for="email">Email address
              <input type="email" id="email" name="email" v-model="email" autocomplete="email_address">
            </label>
          </div>

          <div>
            <button class="reset-button">Reset</button>
          </div>
          <router-link :to="{ name: 'LoginPage'}" class="ghost-button">Sign in</router-link>

        </form>
      </div>

      <div class="password-reset-form" v-if="this.$route.name === 'ResetPasswordConfirm'">
        <h4>Reset password</h4>

        <b-card bg-variant="light" class="my-3">
          <strong>Password Requirements:</strong>
          <ul>
            <li>Cannot be too similar to your other personal information</li>
            <li>Must contain at least 9 characters</li>
            <li>Cannot be a commonly used password</li>
            <li>Cannot be entirely numeric</li>
          </ul>
          <p style='font-size: 0.813rem;' class='mt-3'>Hint: To make it stronger, use upper and lower case letters, numbers, and symbols like ! " ? $ % ^ & ).</p>
        </b-card>

        <notification-banner v-if="resetError"
        theme='error'
        title='Error'
        message='Please try again'
        :show-close-button="false" />
        <notification-banner v-if="resetError === false"
        theme='success'
        title='Success'
        message='Your password has been reset'
        :show-close-button="false" />
        <form class="username" @submit.prevent="resetPassword(resetPasswordPayload)">

          <div>
            <label for="new-pass">New password
              <input type="password" id="new-pass" name="new-password" v-model="newPassword" autocomplete="new-password">
            </label>
          </div>

          <div>
            <label for="confirm-pass">Confirm password
              <input type="password" id="confirm-pass" name="confirm-password" required v-model="confirmPassword" autocomplete="confirm-password">
            </label>
          </div>

          <div>
            <button class="reset-button">Reset</button>
            
          </div>
          <router-link :to="{ name: 'LoginPage'}" class="ghost-button">Sign in</router-link>

        </form>
      </div>

    </div>
    <div class="sign-in-footer">
      <p class="smaller">©{{ `${new Date().getFullYear()}` }} <a href="https://www.orbitalstack.com/">Orbital Stack</a>. All rights reserved. Unauthorized duplication is strictly prohibited.</p>
    </div>
  </div>
</template>

<script>
import { NotificationBanner } from 'rwdi-widgets';
import {
  AUTH_RESET_PASSWORD,
  AUTH_CONFIRM_RESET_PASSWORD,
} from '@/store/actions/auth';
import {
  ERROR,
} from '@/store/statuses/auth';

export default {
  name: 'ResetPassword',
  components: {
    NotificationBanner
  },
  data() {
    return {
      email: null,
      emailError: null,
      newPassword: null,
      confirmPassword: null,
      resetError: null,
    };
  },
  computed: {
    resetPasswordPayload() {
      return { uid: this.$route.params.uid , token: this.$route.params.token, new_password: this.newPassword, re_new_password: this.confirmPassword };
    }
  },
  methods: {
    async sendPasswordResetEmail(email) {
      await this.$store.dispatch(AUTH_RESET_PASSWORD, { email });
      this.emailError = this.$store.getters['authState'].reset_password === ERROR;
    },
    async resetPassword(resetPasswordPayload) {
      await this.$store.dispatch(AUTH_CONFIRM_RESET_PASSWORD, resetPasswordPayload);
      this.resetError = this.$store.getters['authState'].reset_password === ERROR;
    }
  }
};
</script>

<style scoped>
*, * > * {
  box-sizing: border-box!important;
}

body, html {
  height: 100vh;
}

.ghost-button {
  font-size: 0.75em;
  text-align: right;
  font-weight: 700;
}

.sign-in-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  padding: 0;
  margin: 0;
  background-color: var(--primary-navy);
}

.sales-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: center;
  padding: 1.5rem;
  background-color: var(--primary-navy);
}

.sales-cta {
  color: #fff;
}

.cta {
  display: none;
}

.sign-in-footer {
  color: #fff;
  padding: 1em;
  text-align: center;
}

.sign-in-footer a {
  color: #fff;
}

a.button {
  text-decoration: none;
}

.sales-cta a {
  color: #fff;
  margin: 0 auto;
}

.sign-in-logo {
  width: 100%;
  height: auto;
  max-width: 15.625rem;
  padding: 1em

}

.form-wrapper {
  margin: 0;
  flex-grow: 2;
}

.sign-in-form {
  background-color: #fff;
  padding: 2em;
  border-radius: 0.313rem;
  box-shadow: 0 0 0.313rem 0.188rem rgba(0, 0, 0, 0.1);
  max-width: 25rem;
  margin: 0 auto;
}

.password-reset-form {
  background-color: #fff;
  padding: 2em;
  border-radius: 0.313rem;
  box-shadow: 0 0 0.313rem 0.188rem rgba(0, 0, 0, 0.1);
  max-width: 37.5rem;
  margin: 0 auto;
}

.reset-button {
  width: 100%;
}

.sign-in-form .button-row {
  justify-content: space-between;
}

.sign-in-form a {
  align-self: center;
}

.sign-in-form h4 {
  font-size: 1.25em;
}


/* Text Separator */

.text-sep {
  display: inline-block;
  width: 100%;
  background: inherit;
  text-align: center;
  color: var(--grey-700);
  font-weight: 700;
  position: relative;
  overflow: hidden;
}

.text-sep span {
  background-color: #fff;
  display: inline-block;
  padding: 0 0.5em;
  position: relative;
}

.text-sep:before {
  content: '';
  display: inline-block;
  position: absolute;
  left: 0;
  top: calc(50% - 0.125rem);
  width: 100%;
  height: 0.188rem;
  background-color: var(--grey-300);
}


/* Oauth button */

.auth {
  color: var(--grey-700);
  background-color: #fff;
  border: 0.063rem solid var(--grey-400);
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  padding: 0.5em
}

.auth img {
  display: block;
  margin: 0 0.75em 0 0;
  height: 2em;
  width: auto;
}

.auth:hover,
.auth:active {
  color: var(--grey-700);
  background-color: var(--grey-100);
  text-decoration: none;
}

.checkbox-css label:before {
  margin-right: 0.5em;
}


@media screen and (min-width: 52rem) {

  .sign-in-wrapper {
    flex-direction: row;
    background-color: var(--grey-200);
  }

  .cta {
    display: block;
    margin: auto 0;
  }

  .sales-cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: auto;
    max-width: 25rem;
    height: 100%;
    text-align: center;
    padding: 1.5rem;
    background-color: var(--primary-navy);
  }

  .sign-in-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 25rem;
  }
}

</style>